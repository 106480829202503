import React from 'react'
import { motion } from 'framer-motion'

interface LogoProps {}

const Logo: React.FC<LogoProps> = () => {
  const flameAnimation = {
    y: [-1.5, 1.5, 0],
    opacity: 1,
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="201"
      height="74"
      fill="none"
      viewBox="0 0 201 74"
    >
      <motion.path
        fill="#E84E1B"
        fillRule="evenodd"
        d="M48.616 0S33.656 11.006 33.62 26.426l-.002.056v9.112l.002.068v.386c-.025 1.655-.41 7.419-4.44 9.758-3.705 2.15-7.563 1.437-7.61 1.427 1.632 1.42 3.813 2.544 6.72 3.042 1.055.183 2.2.286 3.455.286h1.183c7.68 0 15.621-4.95 15.621-14.564L48.616 0z"
        clipRule="evenodd"
        initial={{ opacity: 0.75 }}
        animate={flameAnimation}
        transition={{ yoyo: Infinity, delay: 0.3 }}
      />
      <motion.path
        fill="#BB3A18"
        fillRule="evenodd"
        d="M33.62 35.678v.37c-.027 1.655-.41 7.42-4.44 9.758-3.707 2.15-7.563 1.436-7.612 1.428 1.631 1.42 3.813 2.541 6.723 3.042.374.064.778.107 1.178.151 2.51-2.416 4.151-5.818 4.151-10.184l.01-4.565h-.01z"
        clipRule="evenodd"
        initial={{ opacity: 0.75 }}
        animate={flameAnimation}
        transition={{ yoyo: Infinity, delay: 8.5 }}
      />
      <path
        fill="#BD1622"
        fillRule="evenodd"
        d="M96.26 16.35c-.253-1.064-.754-1.378-1.76-1.378H54.475V5.566h46.498c3.079 0 5.027.877 5.593 3.636l4.962 23.452c.566 2.76.566 4.703.566 4.703h.127s.062-1.943.753-4.703l6.85-27.025h8.986l6.786 27.025c.692 2.76.755 4.703.755 4.703h.125s0-1.943.564-4.703l4.964-23.452c.566-2.76 2.451-3.636 5.593-3.636h18.822v9.406h-12.349c-1.007 0-1.508.314-1.76 1.379l-8.546 34.048h-12.881l-5.656-20.88c-.815-2.886-.878-4.767-.878-4.767h-.127s-.062 1.881-.88 4.767l-5.655 20.88h-12.88L96.259 16.35z"
        clipRule="evenodd"
      />
      <path
        fill="#BD1622"
        fillRule="evenodd"
        d="M175.053 40.993h1.321c7.416 0 11.939-4.514 11.939-13.105 0-8.528-4.714-12.917-11.939-12.917h-2.703v24.644c0 .876.504 1.378 1.382 1.378zm-12.63-26.022h-4.082V5.567h18.472c14.137 0 23.186 8.212 23.186 22.32 0 14.11-9.049 22.512-23.186 22.512h-9.802c-3.206 0-4.588-1.38-4.588-4.576V14.97zM74.018 27.135c3.643 0 5.403-2.57 5.403-6.144 0-3.575-1.76-6.02-5.214-6.02h-4.399v12.164h4.21zM58.56 14.97h-4.086V5.566h21.805c8.735 0 14.64 6.395 14.64 15.425 0 9.029-5.905 15.55-14.64 15.55h-6.47V50.4H58.56V14.97z"
        clipRule="evenodd"
      />
      <mask
        id="mask0"
        width="16"
        height="42"
        x="0"
        y="6"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M0 6.787h15.005v40.456H0V6.787z"
          clipRule="evenodd"
        />
      </mask>

      <g mask="url(#mask0)">
        <motion.path
          fill="#F8B133"
          fillRule="evenodd"
          d="M15 35.594V6.787S-.003 17.823-.003 33.269v2.325l.002.002c0 .02-.2 7.233 4.86 11.638.046.008 7.15.38 9.199-4.565 1.075-2.601.94-7.075.94-7.075z"
          clipRule="evenodd"
          initial={{ opacity: 0.75 }}
          animate={flameAnimation}
          transition={{ yoyo: Infinity, delay: 0.7 }}
        />
      </g>
      <motion.path
        fill="#CD902C"
        fillRule="evenodd"
        d="M15 35.594v-4.543c-.034 2.251-.262 6.728-1.507 9.68-1.65 3.903-5.345 5.69-8.618 6.503.193.015 7.156.329 9.184-4.565 1.075-2.601.94-7.075.94-7.075z"
        clipRule="evenodd"
        initial={{ opacity: 0.75 }}
        animate={flameAnimation}
        transition={{ yoyo: Infinity, delay: 1.1 }}
      />
      <motion.path
        fill="#F29100"
        fillRule="evenodd"
        d="M31.909 0S16.949 11.006 16.91 26.426l-.002.056v9.112l.002.068v.386c-.023 1.655-.41 7.419-4.44 9.758-3.705 2.15-7.563 1.437-7.61 1.427 1.631 1.42 3.813 2.544 6.72 3.042 1.054.183 2.2.286 3.457.286h1.182c7.68 0 15.62-4.95 15.62-14.564L31.909 0z"
        clipRule="evenodd"
        initial={{ opacity: 0.75 }}
        animate={flameAnimation}
        transition={{ yoyo: Infinity, delay: 0.2 }}
      />
      <mask
        id="mask1"
        width="14"
        height="15"
        x="5"
        y="58"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M5.887 58.077h12.729v14.692H5.886V58.077z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#mask1)">
        <path
          fill="#B2B2B1"
          fillRule="evenodd"
          d="M18.471 59.276v1.212c-1.74-.925-3.446-1.385-5.114-1.385-1.782 0-3.273.604-4.47 1.815-1.2 1.21-1.798 2.72-1.798 4.532 0 1.83.603 3.337 1.809 4.52 1.204 1.182 2.742 1.774 4.611 1.774 1.563 0 3.265-.49 5.107-1.467v1.15c-1.7.893-3.394 1.343-5.085 1.343-2.184 0-4.005-.7-5.46-2.097-1.457-1.399-2.184-3.14-2.184-5.223 0-2.1.743-3.852 2.228-5.26 1.487-1.408 3.257-2.113 5.312-2.113 1.616 0 3.298.4 5.044 1.2z"
          clipRule="evenodd"
        />
      </g>
      <path
        fill="#B2B2B1"
        fillRule="evenodd"
        d="M35.154 71.744c1.788 0 3.294-.61 4.524-1.83 1.231-1.22 1.846-2.715 1.846-4.487 0-1.776-.615-3.275-1.846-4.494-1.23-1.22-2.736-1.831-4.524-1.831-1.795 0-3.313.612-4.557 1.83-1.243 1.22-1.863 2.72-1.863 4.495 0 1.772.62 3.268 1.863 4.487 1.244 1.22 2.762 1.83 4.557 1.83zm-.04-13.666c2.12 0 3.922.712 5.397 2.138 1.477 1.423 2.215 3.163 2.215 5.211 0 2.045-.738 3.78-2.215 5.203-1.475 1.426-3.277 2.139-5.398 2.139-2.115 0-3.909-.713-5.378-2.139-1.47-1.423-2.203-3.158-2.203-5.203 0-2.049.733-3.788 2.203-5.211 1.47-1.425 3.263-2.138 5.378-2.138zM59.366 64.914l5.453-6.652h1.122v14.321h-1.118v-12.7l-5.46 6.694-5.482-6.695v12.701h-1.11v-14.32h1.11l5.485 6.651zM77.734 65.828v5.834h2.968c1.225 0 2.158-.263 2.794-.79.638-.526.957-1.231.957-2.111 0-1.955-1.411-2.933-4.233-2.933h-2.486zm0-6.642v5.71h1.541c2.61 0 3.915-.951 3.915-2.85 0-1.908-1.32-2.86-3.956-2.86h-1.5zm-1.109-.923h2.988c1.57 0 2.757.327 3.565.983.808.656 1.212 1.562 1.212 2.716 0 1.381-.687 2.444-2.063 3.188 2.211.48 3.318 1.69 3.318 3.63 0 1.163-.433 2.088-1.3 2.773-.868.688-2.04 1.03-3.518 1.03h-4.202v-14.32z"
        clipRule="evenodd"
      />
      <mask
        id="mask2"
        width="200"
        height="74"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M0 72.77h199.999V0H0v72.77z"
          clipRule="evenodd"
        />
      </mask>
      <g fillRule="evenodd" clipRule="evenodd" mask="url(#mask2)">
        <path
          fill="#B2B2B1"
          d="M95.114 72.584h1.11v-14.32h-1.11v14.32zM119.789 58.262v14.32h-.913l-10.913-12.515v12.516h-1.108V58.262h.941l10.885 12.518V58.262h1.108zM138.902 66.71l-3.093-6.746-3.082 6.745h6.175zm-2.777-8.551l6.635 14.426h-1.165l-2.27-4.953h-7.019l-2.262 4.953h-1.165l6.635-14.426h.611zM160.057 58.262v.923h-5.382v13.398h-1.12V59.185h-5.384v-.923h11.886zM169.16 72.584h1.108v-14.32h-1.108v14.32zM188.768 58.262v.923h-6.718v5.731h6.543v.933h-6.543v5.813h6.882v.92h-7.993v-14.32h7.829z"
        />
        <path
          fill="#891821"
          d="M69.853 14.971h4.352c2.377 0 3.952 1.157 4.703 3.042h11.777a17 17 0 00-.76-3.042H69.852zM162.424 18.011h11.248v-3.042h-11.248v3.042z"
        />
      </g>
    </svg>
  )
}

export default Logo
