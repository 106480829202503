import React from 'react'
import { motion } from 'framer-motion'

interface FlameProps {}

const Flame: React.FC<FlameProps> = () => {
  const flameAnimation = {
    y: [-1.5, 1.5, 0],
    opacity: 1,
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="none"
      viewBox="0 0 19 19"
    >
      <motion.path
        fill="#E84E1B"
        fillRule="evenodd"
        d="M18.267 0s-5.61 4.136-5.623 9.93l-.001.022v3.594c-.009.622-.153 2.788-1.664 3.667-1.39.808-2.836.54-2.854.536.612.534 1.43.956 2.52 1.143.396.07.825.108 1.296.108h.443c2.88 0 5.857-1.86 5.857-5.473L18.267 0z"
        clipRule="evenodd"
        initial={{ opacity: 0.75 }}
        animate={flameAnimation}
        transition={{ yoyo: Infinity, delay: 0.3 }}
      />
      <motion.path
        fill="#BB3A18"
        fillRule="evenodd"
        d="M12.643 13.38v.139c-.01.624-.154 2.796-1.664 3.678-1.39.81-2.835.541-2.854.538.612.535 1.43.958 2.52 1.147.14.024.292.04.442.057.94-.911 1.556-2.193 1.556-3.839l.003-1.72h-.003z"
        clipRule="evenodd"
        initial={{ opacity: 0.75 }}
        animate={flameAnimation}
        transition={{ yoyo: Infinity, delay: 0.5 }}
      />
      <motion.path
        fill="#F8B133"
        fillRule="evenodd"
        d="M5.619 13.355V2.565S0 6.7 0 12.485v.871c0 .008-.075 2.709 1.82 4.359.018.003 2.679.142 3.446-1.71.403-.974.353-2.65.353-2.65z"
        clipRule="evenodd"
        initial={{ opacity: 0.75 }}
        animate={flameAnimation}
        transition={{ yoyo: Infinity, delay: 0.7 }}
      />
      <motion.path
        fill="#CD902C"
        fillRule="evenodd"
        d="M5.619 13.366v-1.697c-.013.84-.098 2.513-.564 3.616-.616 1.457-1.998 2.125-3.222 2.429.072.006 2.676.123 3.434-1.705.402-.972.352-2.643.352-2.643z"
        clipRule="evenodd"
        initial={{ opacity: 0.75 }}
        animate={flameAnimation}
        transition={{ yoyo: Infinity, delay: 0.9 }}
      />
      <motion.path
        fill="#F29100"
        fillRule="evenodd"
        d="M11.974 0S6.365 4.136 6.351 9.93l-.001.022v3.594c-.008.622-.153 2.788-1.664 3.667-1.39.808-2.836.54-2.853.536.611.534 1.43.956 2.52 1.143A7.53 7.53 0 005.648 19h.444c2.879 0 5.856-1.86 5.856-5.473L11.974 0z"
        clipRule="evenodd"
        initial={{ opacity: 0.75 }}
        animate={flameAnimation}
        transition={{ yoyo: Infinity, delay: 1.1 }}
      />
    </svg>
  )
}

export default Flame
